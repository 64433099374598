body {
  margin: 0;
  background-color: rgb(0, 68, 129) !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ol, ul {
  list-style-position: outside !important;
}

.loadingPage {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: all 1s;
}

.containerLoading {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.clearfix {
  float: none;
}

.screenCollapsed {
  height: auto;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* @media (max-width: 340px) {
  .screenCollapsed {
    height: auto;
  }
} */
.iphone-selected-lottie-container div div div {
  margin-left: -60% !important;
}
