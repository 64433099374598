.root {
  margin: 0 auto;
}

.loading {
  display: flex;
  justify-content: center;
}

.loading::after {
  content: "";
  width: 50px;
  height: 50px;
  border: 10px solid white;
  border-top-color: #004481;
  border-radius: 50%;
  animation: loading 1s ease infinite;
}

@keyframes loading {
  to {
    transform: rotate(1turn);
  }
}
